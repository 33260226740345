import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
// import apolloProvider from './services/apollo-client'
import Multiselect from 'vue-multiselect'
import dialog from '@/components/Confirm'
import {urlImage,urlImageFile,urlEditorImage,urlImageLogo} from './services/url'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import { ValidationObserver } from 'vee-validate';
import InfiniteLoading from 'vue-infinite-loading';
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.prototype.$urlImageFile = urlImageFile
Vue.prototype.$urlEditorImage = urlEditorImage
Vue.prototype.$urlImage = urlImage
Vue.prototype.$urlImageLogo = urlImageLogo
Vue.prototype.$urlImageShow = 'https://storage.googleapis.com/108-bucket/'
Vue.config.productionTip = false
Vue.use(dialog)
Vue.use(InfiniteLoading, { /* options */ });


new Vue({
  //apolloProvider,
  router,
  store,
  Multiselect,
  ValidationProvider,
  ValidationObserver,
  render: h => h(App)
}).$mount('#app')
